import React from 'react';
import Logo from '../../components/Logo';
import Contact from '../../components/Contact';
import withIntl from '../../i18n/withIntl';
import '../../../assets/styles/Web.scss';

const GdprConfirmEn = () => (
  <>
    <a className="Section Section--contact Section--themeLight" href="https://www.sqn.cz">
      Go back to the main page
    </a>
    <section className="Section Section--contact Section--themeLight">
      <div className="Section-main">
        <Logo />
        <h2 className="Section-mainTitle">Thank you for your agreement</h2>
        <p>We are looking forward to the next cooperation</p>
      </div>
    </section>
    <footer className="Footer">
      <Contact />
    </footer>
  </>
);

export default withIntl(GdprConfirmEn);
